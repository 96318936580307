<p-sidebar [(visible)]="showMenu" 
[modal]="false"
styleClass="advance-filter-dialog" (onHide)="onHideSideMenu()" position="right">
    <ng-template pTemplate="header">
        <div>
            Advanced Filters <span class="shelf-product-indicator font-16"> (<i class="pi pi-star-fill"></i> indicates
                shelf
                product)</span>
            <br />
            <span class="font-16 fw-normal">{{ searchTitle }}</span>
        </div>
    </ng-template>
    <hr />
    <ng-template pTemplate="content">
        <ng-container *ngIf="showSpinner; else showContent">
            <div class="text-center">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                    styleClass="custom-spinner"></p-progressSpinner>
            </div>
        </ng-container>
        <ng-template #showContent>
            <div class="">
                <ng-container
                    *ngIf="(filteredSavedItem > 0) || (selectedCustomShelfName && selectedCustomShelfName != '' && selectedCustomShelfName != 'Select')">
                    <div class="my-2 pt-2">
                        <div class="row clearFilter">
                            <div class="col-8">
                                <label>{{ filteredSavedItem }} filters
                                    applied</label>
                            </div>
                            <div class="col-4 pr-3">
                                <label class="text-danger justify-content-end" (click)="clearAllFilter()">Clear
                                    All</label>
                            </div>
                        </div>
                    </div>
                    <div class="chips-details pl-3">
                        <p-chip [removable]="true" styleClass="m-1 custom-chip"
                            *ngFor="let item of SelectFilter.concat(
                                                                                                                                                                                                                                        filterdataobj_group_name
                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                        .concat(SelectFilter_AMbest_name)
                                                                                                                                                                                                                                        .concat(
                                                                                                                                                                                                                                            filterdataobj_surrender_year_name
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                        .concat(filterdataobj_products_name)
                                                                                                                                                                                                                                        .concat(
                                                                                                                                                                                                                                            filterdataobj_strategyfee_name
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                        .concat(filterdataobj_riderfee_name)
                                                                                                                                                                                                                                "
                            (onRemove)="removeTags(item)">
                            {{
                            item.groupName
                            ? item.groupName
                            : "" || item.data
                            ? item.data
                            : "" ||
                            item.maxRiderFee ||
                            item.bestid
                            ? "AM Best" + " " + item.bestid
                            : "" || item.surryr
                            ? "Min. Sur
                            Yr." +
                            " " +
                            item.surryr
                            : "" || item.riderFee
                            ? "Min Fee" + " " + item.riderFee
                            : "" ||
                            item.strategyFee ||
                            item.confiMulti
                            }}
                        </p-chip>
                        <p-chip [removable]="true" [label]="selectedCustomShelfName"
                            *ngIf="selectedCustomShelfName && selectedCustomShelfName != 'Select'"
                            (onRemove)="removeShelf()" styleClass="m-1 custom-chip"></p-chip>
                    </div>
                </ng-container>
                <div class="row align-items-center layoutFont">
                    <div class="col-12 p-3">
                        <div class="flex align-items-center justify-content-between">
                            <label class=" layoutFontsort">Shelf Management</label>
                            <a pButton label="Add" class="annuity-menu-btn" (click)="addCustomSelf()"></a>
                        </div>
                        <div class="flex align-items-center justify-content-between">
                            <p-dropdown [options]="userCustomShelfs" class="dwopdown custom-shelf-dropdown"
                                optionLabel="shelfName" optionValue="shelfName" [(ngModel)]="selectedCustomShelfName"
                                [disabled]="userCustomShelfs.length == 0" (onChange)="filterByCustomShelf($event)">
                            </p-dropdown>
                            <a pButton label="Edit"
                                *ngIf="userCustomShelfs.length > 0 && selectedCustomShelfName != 'Select'"
                                (click)="onEditCustomShelf()" class="annuity-menu-btn btn-edit-shelf"></a>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row align-items-center layoutFont">
                    <div class="col-3 p-3">
                        <label class="layoutFontsort">Sort by</label>
                    </div>
                    <div class="col-9 pt-0 pl-0 pr-0">
                        <p-dropdown [options]="sortByDataOption" class="dwopdown" optionLabel="sortByDataOption"
                            optionLabel="name" [(ngModel)]="selectedSortBymodel" optionValue="value"
                            (onChange)="shortByFilters($event)">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row align-items-center layoutFont" *ngIf="shelfPermissionIA !== 'shelf'">
                    <div class="col-12 px-3">
                        <div class="flex layoutFontsort customShelfhead ">
                            <span id="AllProducts">All Products</span>
                            <p-inputSwitch aria-label="Approved Products" styleClass="mx-2"
                            inputId="showApprovedProducts" (onChange)="onApprovedProducts($event)"
                            [(ngModel)]="showApprovedProducts"></p-inputSwitch>
                            <span id="ApprovedProducts">Approved Products </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Sidebar' && !showSpinner">
                <div class="card-overflow card sidebarListLayout py-2 px-3">
                    <ul class="layout-menu">
                        <li class="menu-separator1 sidebar-menu-data row slideInLeft"
                            (click)="onTabSelect('Group Name')">
                            <div class="col-10">
                                <label>Group Name</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft1" (click)="
                                                                            onTabSelect(
                                                                                'Company & Product Name'
                                                                            )
                                                                        ">
                            <div class="col-10">
                                <label>Company & Product Name</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft2" (click)="
                                                                            onTabSelect('A.M. Best Rating')
                                                                        ">
                            <div class="col-10">
                                <label>A.M. Best Rating</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft3"
                            (click)="onTabSelect('Surrender Year')">
                            <div class="col-10">
                                <label>Surrender Year</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft4"
                            (click)="onTabSelect('Rider Fee')">
                            <div class="col-10">
                                <label>Rider Fee</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft5"
                            (click)="onTabSelect('Strategy Fee')">
                            <div class="col-10">
                                <label>Strategy Fee</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>

                        <li class="menu-separator sidebar-menu-data row slideInLeft6" (click)="
                                                                            onTabSelect(
                                                                                'Confinement Multiplier'
                                                                            )
                                                                        ">
                            <div class="col-10">
                                <label>Confinement Multiplier</label>
                            </div>
                            <div class="col-2">
                                <i class="pi pi-chevron-right"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Group Name'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Group Name</label>
                        </div>
                    </div>
                    <div>
                        <div class="filter-card">
                            <div class="card-body">
                                <p-listbox [options]="groups" [(ngModel)]="selectedGroups" (onClick)="
                                                                                    onGroupSelect($event)
                                                                                " [metaKeySelection]="false"
                                    [checkbox]="true" [filter]="true" [multiple]="true" optionLabel="groupName"
                                    [listStyle]="{
                                                                                    'max-height': '150rem'
                                                                                }">
                                    <ng-template let-group pTemplate="item ">
                                        <div class="country-item">
                                            <div>
                                                {{
                                                group.groupName
                                                }}
                                                ({{ group.total }})
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Company & Product Name'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>
                        <div class="col-10">
                            <label class="">Company & Product Name</label>
                        </div>
                    </div>
                    <div>
                        <div class="filter-card">
                            <div class="card-body">
                                <p-tree [value]="productList" [(selection)]="SelectFilter4" selectionMode="checkbox"
                                    [filter]="true" (onNodeSelect)="onProductSelect($event)"
                                    (onNodeUnselect)="onProductRemove($event) ">
                                    <ng-template let-node pTemplate="product">
                                        <span>{{node.label}} <i class="pi pi-star-fill"
                                                *ngIf="node.isShelfProduct"></i></span>
                                    </ng-template>
                                    <ng-template let-node pTemplate="default">
                                        <span [ngClass]="{'parent-node': isParentNodeSelected(node)}">{{node.label}}
                                            <i class="pi pi-star-fill" *ngIf="node.hasShelfProduct"></i></span>
                                    </ng-template>
                                </p-tree>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'A.M. Best Rating'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">A.M. Best Rating</label>
                        </div>
                    </div>
                    <div>
                        <div class="filter-card">
                            <div class="card-body">
                                <p-listbox [options]="amBestRating" [(ngModel)]="selectedAMBestrating"
                                    [metaKeySelection]="false" [checkbox]="true" [multiple]="true" [filter]="true"
                                    optionLabel="bestid" optionDisabled="active" [listStyle]="{'max-height': '150rem'}"
                                    (onClick)="onAMReatingSelect($event)">
                                    <ng-template pTemplate="header">
                                        Minimum Rating
                                    </ng-template>
                                    <ng-template let-amRating pTemplate="item">
                                        <div class="country-item">
                                            <div>
                                                {{
                                                amRating.bestid
                                                }}
                                                ({{
                                                amRating.total
                                                }})
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Surrender Year'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Surrender Year</label>
                        </div>
                    </div>
                    <div>
                        <div class="filter-card">
                            <div class="card-body">
                                <p-listbox [options]="surrenderYear" [(ngModel)]="selectedSuryear"
                                    [metaKeySelection]="false" [checkbox]="true" [multiple]="true" [filter]="true"
                                    optionLabel="name" optionLabel="hii" (onClick)="
                                                                                    onSurYearSelect($event)
                                                                                ">
                                    <ng-template pTemplate="header">
                                        Maximum Surrender Year
                                    </ng-template>
                                    <ng-template let-surrenderYr pTemplate="item">
                                        <div class="country-item">
                                            <div>
                                                {{
                                                surrenderYr.surryr
                                                }}
                                                ({{
                                                surrenderYr.total
                                                }})
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-listbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Rider Fee'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Rider Fee</label>
                        </div>
                    </div>
                    <div class="p-3">
                        <div class="filter-card">
                            <div class="card-body">
                                <div>
                                    <label class="text-muted">Maximum Fee</label>
                                </div>
                                <div class="mt-2">
                                    <p-dropdown [options]="riderFeeData" [(ngModel)]="SelectRiderFee"
                                        optionLabel="riderFee" placeholder="Select" [style]="{
                                                                                        width: '15rem'
                                                                                    }" (onChange)="
                                                                                        onRiderFeeSelect($event)
                                                                                    ">
                                        <ng-template let-rideFee pTemplate="item">
                                            <div class="country-item">
                                                <div>
                                                    {{
                                                    rideFee.riderFee
                                                    }}%
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label class="font-italic drp-down ml-2">or less</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Strategy Fee'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Strategy Fee</label>
                        </div>
                    </div>
                    <div class="p-3">
                        <div class="filter-card">
                            <div class="card-body">
                                <div>
                                    <p-dropdown [options]="strategyFeeOptions" [(ngModel)]="
                                                                                        selectedStrategyFee
                                                                                    " optionLabel="strategyFee"
                                        placeholder="Select" [style]="{
                                                                                        width: '15rem'
                                                                                    }" (onChange)="
                                                                                        onStrategyFeeSelect(
                                                                                            $event
                                                                                        )
                                                                                    ">
                                        <ng-template let-strFee pTemplate="item">
                                            <div class="country-item">
                                                <div>
                                                    {{
                                                    strFee.strategyFee
                                                    }}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Confinement Multiplier'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Confinement Multiplier</label>
                        </div>
                    </div>
                    <div class="p-3">
                        <div class="filter-card">
                            <div class="card-body">
                                <div>
                                    <p-dropdown [options]="confiMultiplier" [(ngModel)]="SelectFilter6"
                                        optionLabel="confiMulti" placeholder="Select" [style]="{
                                                                                        width: '15rem'
                                                                                    }" (onChange)="
                                                                                        onConfiMultiSelect(
                                                                                            $event
                                                                                        )
                                                                                    ">
                                        <ng-template let-confiMult pTemplate="item">
                                            <div class="country-item">
                                                <div>
                                                    {{
                                                    confiMult.confiMulti
                                                    }}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedtab == 'Short by'">
                <div class="slideInRight py-2 px-3">
                    <div class="sidebar-menu-data-back row" (click)="onBackClick()">
                        <div class="col-2">
                            <i class="pi pi-chevron-left"></i>
                        </div>

                        <div class="col-10">
                            <label class="">Sort by</label>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="searchButton row p-3" *ngIf="!showSpinner">
            <div class="col-6">
                <button pButton pRipple type="button" label="SAVE SEARCH"
                    class="p-button-outlined p-button-secondary search-btn" (click)="saveContinue()" *ngIf="
                                                                            savedType != 'edit' &&
                                                                            savedType != 'view' &&
                                                                            savedType == ''
                                                                        "></button>
                <button pButton pRipple type="button" label="UPDATE SEARCH"
                    class="p-button-outlined p-button-secondary search-btn" (click)="saveContinue()"
                    *ngIf="savedType == 'edit'"></button>
            </div>
            <div class="col-6">
                <button pButton pRipple type="button" label="VIEW SAVED SEARCHES"
                    class="p-button-outlined p-button-secondary search-btnView mb-3" (click)="savedSearches()"></button>
            </div>
        </div>
    </ng-template>
    <!-- </ng-template> -->
</p-sidebar>

<!-- Save Search Dualog(POP-UP)-->
<p-dialog header="Save this Search" [(visible)]="savedSearch" (onHide)="searchDataForm.reset()" [modal]="true"
    [style]="{ width: '508px', height: '320px' }" [baseZIndex]="10000">
    <p-header>
        <div class="searchHeading">
            <label>Save this Search</label>
        </div>
    </p-header>
    <hr class="m-0" />
    <form [formGroup]="searchDataForm" class="mx-2" (ngSubmit)="saveSearch()">
        <div class="searchData">
            <div class="d-grid">
                <label class="mb-2">Custom name your search</label>
                <input type="text" formControlName="searchtitle" placeholder="Enter search name...." pInputText
                    [(ngModel)]="searchtitle" formControlName="searchtitle" required />
                <div *ngIf="searchDataFormControls.searchtitle.invalid && ((searchDataFormControls.searchtitle.dirty || searchDataFormControls.searchtitle.touched) || saveFormSubmitted)"
                    class="alert alert-danger form-danger" role="alert">
                    <div
                        *ngIf="searchDataFormControls.searchtitle.errors.required || searchDataFormControls.searchtitle.errors.whitespace">
                        Please enter search name.
                    </div>
                </div>
            </div>
        </div>
        <div class="my-4 text-center">
            <div class="searchSave">
                <button type="button" pButton class="m-2"
                    *ngIf="savedType != 'edit' && savedType != 'view' && savedType == ''" (click)="saveSearch()">
                    Continue
                </button>
                <button type="button" pButton class="m-2" *ngIf="savedType == 'edit'" (click)="updateContinue()">
                    Continue
                </button>
            </div>
        </div>
    </form>
</p-dialog>