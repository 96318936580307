import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GridData } from '../models/customer';
import { ProductDetails } from '../models/product';
import { AES, enc } from 'crypto-js';
import { ProductRidersRes, ResultSet } from '../models/benefit-calculator.model';

@Injectable({
    providedIn: 'root',
})
export class BenefitcalculatorService {
    accessToken: string = '';
    env = environment.apiroot;
    apiKey = 'kmpqwehducvklmmpdsdifrvbmkhdmp';
    secretKey = '1234567891023456';
    // key = CryptoJS.enc.Utf8.parse(environment.EncryptKey);
    // iv = CryptoJS.enc.Utf8.parse(environment.EncryptIV);
    headers: any;
    allProducts: any[] = [];
    resultSet!: ResultSet;

    constructor(private http: HttpClient) {
        this.accessToken = this.get('Token');

        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
        });
    }

    public cartItemData = new BehaviorSubject<any>([]);
    public saveItemData = new BehaviorSubject<any>([]);

    getAMRatingDetails() {
        return this.http.get<any>(this.env + 'Filters/GetAMBest');
    }

    getSurrenderYrDetails() {
        return this.http.get<any>(this.env + 'Filters/GetSurrenderYr');
    }

    getRiderFeeDetails() {
        return this.http.get<any>(this.env + 'Filters/GetRiderFee');
    }

    getStrategyFeeDetails() {
        return this.http.get<any>(this.env + 'Filters/GetStrategyFee');
    }

    getStateData() {
        return this.http.get<any>(this.env + 'Filters/GetStates');
    }

    getIllustrationData(fnlobj: any) {
        return this.http.post<any>(this.env + 'Rider/GetIllustration', fnlobj);
    }

    getProductProfile(id: any, state: any) {
        return this.http.get<any>(this.env + `Product/GetProductProfile/${id}/${state}`);
    }

    // Logo
    getAllCompanyData() {
        return this.http.get<any>(this.env + 'Company/GetAll');
    }

    getCompanyDatabyId(id: any) {
        return this.http.get<any>(this.env + `Company/Get/${id}`);
    }

    postLogoData(finalObj: any) {
        return this.http.post<any>(this.env + 'Company/UploadLogo', finalObj);
    }

    postDefaultSetting(obj: any) {
        return this.http.post<any>(this.env + 'User/DefaultSettings', obj);
    }

    getDefaultSetting(id: any) {
        return this.http.get<any>(this.env + `User/GetDefaultSettings/${id}`);
    }

    postSaveSearch(obj: any) {
        return this.http.post<any>(this.env + 'Search/AddSearch', obj);
    }

    putSaveSearch(obj: any) {
        return this.http.put<any>(this.env + 'Search/UpdateSearch', obj);
    }
    getSavedSearch(id: any) {
        return this.http.get<any>(this.env + `Search/GetSearch/${id}`);
    }

    deleteSavedSearch(id: any) {
        return this.http.delete<any>(this.env + `Search/DeleteSearch/${id}`);
    }

    getUser(userId: number) {
        return this.http.get<any>(this.env + `User/GetUserById/${userId}`);
    }

    putProfileSettings(obj: any) {
        return this.http.put<any>(this.env + 'User/UpdateProfileSettings', obj);
    }
    getAllCompanies() {
        return this.http.get<any>(this.env + 'User/GetUserCompanies');
    }
    getAccreditedDesignations() {
        return this.http.get<any>(this.env + 'User/GetAccreditedDesignation');
    }

    getProducsCustomShelf() {
        return this.http.get<any>(this.env + 'Filters/GetProducts');
    }

    // POST APIs

    postProductsDetails(finalObj: any) {
        return this.http.post<ProductRidersRes>(this.env + 'Rider/GetRiderFactors', finalObj);
    }

    postCustomShelfnew(obj: any) {
        return this.http.post<any>(this.env + 'UserShelf/Add', obj);
    }

    postUpdateCustomShelfnew(obj: any) {
        return this.http.get<any>(this.env + `UserShelf/GetByUserId/${obj}`);
    }

    deleteCustomShelf(id: number, shelfName: string) {
        return this.http.delete<any>(this.env + `UserShelf/Delete/${id}/${shelfName}`);
    }

    //////data service////////
    setCartItemData(data: any) {
        this.cartItemData.next(data);
    }

    getCartItemData() {
        return this.cartItemData.asObservable();
    }

    get(key: string): any {
        if (key != null) {
            const value = sessionStorage.getItem(key);
            if (value != null) {
                return JSON.parse(value ? value : '');
            }
        }
    }

    setsavedData(data: any) {
        this.saveItemData.next(data);
    }

    getsavedData() {
        return this.saveItemData.asObservable();
    }


    setsavedEditData(data: any) {
        this.saveItemData.next(data);
    }

    getsavedEditData() {
        return this.saveItemData.asObservable();
    }
    //encrypt decrypt
    encryptUsingAES256(text: any) {
        const encryptedMessage = AES.encrypt(text, this.secretKey).toString();
        return encryptedMessage;
    }

    decryptUsingAES256(decString: any) {
        const decryptedMessage = AES.decrypt(decString, this.secretKey).toString(enc.Utf8);
        return decryptedMessage;
    }

    getAMBestRatings() {
        return this.http.get<any>(this.env + 'Filters/GetAMBest');
    }

    getUserCustomShelfs(userId: number) {
        return this.http.get<any>(this.env + `UserShelf/GetByUserId/${userId}`);
    }
}
